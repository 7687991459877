import { debounce } from '../helper/functions';
import { headerHeight } from '../helper/constants';

class HeaderWrapper extends HTMLElement {
  constructor() {
    super();
    this.scrollThreshold = 70; // Set scroll distance threshold
    this.search = this.querySelector(`#search-modal`);
    this.scrollPosition = window.scrollY || document.documentElement.scrollTop;
    this.template = this.getAttribute('template');
    this.invertText = this.getAttribute('text-invert');

    this.announcement = document.querySelector('announcement-slider');
    this.header = document.querySelector('header');

    //LOGO | DESKTOP NAV MENU | BACKGROUND
    this.invertedElements = {
      logo: this.querySelector('#header-logo'),
      menu: this.querySelectorAll('.header-desktop-menu'),
      background: this.querySelector('#header-background')
    };

    this.invertedTemplates = ['index'];

    // Debounce the headerHeight calculation
    this.updateHeaderHeight = debounce(() => {
      headerHeight();
    }, 100); // 100ms debounce time
  }

  connectedCallback() {
    //document.querySelector('#MainContent').firstElementChild.style.marginTop = this.announcement.clientHeight + 'px';
    this.initScrollListener();
  }

  disconnectedCallback() {
    if (this.handleScroll) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  initScrollListener() {
    this.handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (scrollTop > this.scrollThreshold) {
        // When scrolled past threshold, move header up to top of viewport
        this.header.classList.add('top-0');
        //if there are announcements, set their height
        if (this.announcement) {
          this.announcement.clientHeight;
        }
      } else {
        // Reset header position to accommodate announcement bar and global-e banner
        this.header.classList.remove('top-0');
        //if there are announcements, set their height
        if (this.announcement) {
          this.announcement.clientHeight;
        }
      }

      // Update header height when scrolling
      this.updateHeaderHeight();
    };

    window.addEventListener('scroll', this.handleScroll);

    // Initial calculation
    headerHeight();
  }
}

customElements.define('header-wrapper', HeaderWrapper);

class HamburgerMenu extends HTMLElement {
  constructor() {
    super();

    this.burger = this.querySelector('.burger');
    this.close = this.querySelector('.close');

    this.addEventListener('click', this.toggle);
  }

  toggle() {
    this.burger.classList.toggle('opacity-0');
    this.close.classList.toggle('opacity-0');
  }
}

customElements.define('hamburger-menu', HamburgerMenu);

class Search extends HTMLElement {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      query: ''
    };
    this.dom = {
      form: document.querySelector('.js-search-form'),
      bar: document.querySelector('.js-search-form-bar'),
      search: document.querySelector('.js-search-input'),
      close: document.querySelector('.js-close-search'),
      header: document.querySelector('header-wrapper')
    };
    this.addEventListener('click', this.toggle.bind(this));
    this.dom.close.addEventListener('click', this.toggle.bind(this));
    this.dom.search.addEventListener(
      'input',
      debounce(() => this.handleSearch.bind(this), 250)
    );
    this.dom.form.addEventListener('submit', this.handleSubmit.bind(this));
  }
  toggle() {
    const { state, dom } = this;
    state.isOpen = !state.isOpen;
    state.query = '';

    dom.bar.classList.add('translate-y-[-100vh]');
    // dom.header.classList.remove('inverted-nav');
    dom.search.value = state.query;

    if (state.isOpen) {
      dom.bar.classList.remove('translate-y-[-100vh]');
      // dom.header.classList.add('inverted-nav');
      dom.search.focus();
    } else {
      dom.search.blur();
    }
  }
  handleSearch() {
    const { state, dom } = this;
    const { search } = dom;
    const searchTerm = search.value.trim();

    return (state.query = searchTerm);
  }
  handleSubmit(e) {
    e.preventDefault();

    const { state, dom } = this;
    state.query = `/search?q=${state.query}`;
    dom.form.action = state.query;

    return dom.form.submit();
  }
}

customElements.define('search-toggle', Search);
